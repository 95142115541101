import React, { useContext } from 'react';
import { ComboBox } from 'carbon-components-react';
import { IndexProvider, IndexContext } from '../../store/index-context';
import { DetailContext } from '../../store/detail-context';
import { colorKey } from './search-bar.module.scss';

const SearchBarComponent = () => {
  const { isLoading, keywords: items } = useContext(IndexContext);
  const { setMarkers, setSelected } = useContext(DetailContext);

  function selectItem({ selectedItem }) {
    setMarkers(selectedItem ? selectedItem.matches : null);
    setSelected(selectedItem ? selectedItem.text : null);
  }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'center', fontSize: '0.8rem' }}>
        <div className={colorKey} style={{ backgroundColor: '#3d70b2' }}></div>
        <span style={{ display: 'inline-block' }}>Matches Any Filter</span>
        <div className={colorKey} style={{ backgroundColor: '#7ad153', marginLeft: '25px' }}></div>
        <span style={{ display: 'inline-block' }}>Matches All Filters</span>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0px' }}>
        <div style={{ display: 'flex', width: 500 }}>
          <ComboBox
            {...{ items }}
            itemToString={item => (item ? item.text : '')}
            onChange={selectItem}
            placeholder={isLoading ? 'Loading...' : 'Search ...'}
          />
        </div>
      </div>
    </div>
  );
};

const SearchBar = () => (
  <IndexProvider>
    <SearchBarComponent />
  </IndexProvider>
);

export default SearchBar;

import React, { useReducer, useMemo } from 'react';

const UserContext = React.createContext();
const { Provider } = UserContext;

function reducer(state, action) {
  switch (action.type) {
    case 'setAuthData':
      if (action.payload) {
        const { userName: payloadUserName, attributes } = action.payload;
        const { name: userName } = attributes ? attributes : {};
        if (userName && state.userName !== userName) {
          state = { ...state, userName };
        } else if (payloadUserName && state.userName !== userName) {
          state = { ...state, ...{ userName: 'UNKNOWN' } };
        }
      } else {
        console.warn(action);
      }
      break;
    default:
      console.warn(`unknown action type ${action.type}`);
  }
  return state;
}

function UserProvider(props) {
  const [state, dispatch] = useReducer(reducer, {
    userName: null
  });

  const setAuthData = useMemo(() => {
    return congitoUser =>
      dispatch({ type: 'setAuthData', payload: congitoUser });
  }, [dispatch]);

  return (
    <Provider value={{ ...state, setAuthData }}>{props.children}</Provider>
  );
}

export { UserProvider, UserContext };

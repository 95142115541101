import React, { useContext } from 'react';
import { InlineLoading, Tabs, Tab } from 'carbon-components-react';
import ProductTable from '../product-table/product-table';
import EnvironmentTable from '../environment-tab/environment-tab';
import People from '../people/people';
import { DetailContext } from '../../store/detail-context';
const SidebarNav = () => {
  const { overview, isLoading } = useContext(DetailContext);
  const { productGroups, environments, ism_legend: ismLegend } = overview;

  // let { notes } = overview;

  // notes = notes?.replace(/\r\n/g, '<br>') ?? '<em>No Notes at This Time...</em>';

  return (
    <Tabs>
      <Tab label="Products">
        <div>
          {isLoading ? (
            <InlineLoading
              description="Loading..."
              style={{ marginLeft: '1rem' }}
              success={false}
            />
          ) : productGroups && productGroups.length > 0 ? (
            productGroups.map((group, key) => {
              return (
                <div style={{ marginTop: 25 }} {...{ key }}>
                  <ProductTable {...{ group, ismLegend }} />
                </div>
              );
            })
          ) : (
            'No active licenses or installations found.'
          )}
        </div>
      </Tab>
      <Tab label="Contacts">
        <div>
          {isLoading ? (
            <InlineLoading
              description="Loading..."
              style={{ marginLeft: '1rem' }}
              success={false}
            />
          ) : (
            <People />
          )}
        </div>
      </Tab>
      <Tab label="Environments">
        {isLoading ? (
          <InlineLoading description="Loading..." style={{ marginLeft: '1rem' }} success={false} />
        ) : environments && environments.length > 0 ? (
          environments.map((env, key) => {
            return (
              <div style={{ marginTop: 25 }} {...{ key }}>
                <EnvironmentTable {...{ env }} />
              </div>
            );
          })
        ) : (
          'No active licenses or installations found.'
        )}
      </Tab>
      {/* <Tab label="Notes">
        {isLoading ? (
          <InlineLoading description="Loading..." style={{ marginLeft: '1rem' }} success={false} />
        ) : (
          <div dangerouslySetInnerHTML={{ __html: notes }}></div>
        )}
      </Tab>*/}
    </Tabs>
  );
};
export default SidebarNav;

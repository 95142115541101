import React from 'react';
import Tooltip from './tooltip.js';
import { TooltipProvider } from '../../store/map-tooltip-context';
import MapComponent from './map-component';

const Map = () => (
  <TooltipProvider>
    <MapComponent />
    <Tooltip />
  </TooltipProvider>
);

export default Map;

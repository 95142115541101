// import t from 'typy';

// const sortByName = (a, b) => {
//   const nameA = (a ? a : '').name.toUpperCase();
//   const nameB = (b ? b : '').name.toUpperCase();
//   if (nameA < nameB) {
//     return -1;
//   }
//   if (nameA > nameB) {
//     return 1;
//   }
//   // names must be equal
//   return 0;
// };

// const safeArray = (obj, path) => {
//   const safeObj = t(obj, path).safeObject;
//   if (t(safeObj).isArray) {
//     return safeObj;
//   }
//   console.warn(`${path} is not an array in:`);
//   console.log(obj);
//   return [];
// };

// const trnsalateEnvironments = payload => {
//   return safeArray(payload, 'environments')
//     .map(x => (x ? x : {}))
//     .map(env => {
//       let {
//         Name: name,
//         Active__c: isActive,
//         Environment_Type__c: type,
//         Database_Platform__c: database,
//         Components__c: components,
//         Hosted_Type__c: hosted
//       } = env;
//       return { name, isActive, type, database, components, hosted };
//     })
//     .sort(sortByName);
// };

export const translate = payload => payload;

import React, { useReducer, useEffect, createContext } from 'react';
import StateNames from './state-names';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import t from 'typy';
import { translate } from './keyword-translator';

const Context = createContext();
const { Provider } = Context;

const defaultKeywords = Object.keys(StateNames).map(x => ({
  id: `stateName:${x}`,
  text: x,
  matches: x,
}));

const sortByText = (a, b) => {
  const textA = a.text.toUpperCase();
  const textB = b.text.toUpperCase();
  if (textA < textB) return -1;
  if (textA > textB) return 1;
  return 0;
};

const downloadKeywords = async dispatch => {
  const { accessToken } = await Auth.currentSession();
  const jwtToken = accessToken ? accessToken.jwtToken : '';
  const config = {
    headers: { Authorization: `Bearer ${jwtToken}`, responseType: 'json' },
  };

  const baseUrl = 'https://www.aashtowareproject.org';
  const url = `${baseUrl}/sf_api/organizations/overview`;

  try {
    const { data } = await axios.get(url, config);
    const payload = t(data).isEmptyObject ? {} : data;
    // console.dir(payload);
    const allKeywords = [...translate(payload), ...defaultKeywords].sort(sortByText);
    // console.dir(allKeywords);
    dispatch({ type: 'set-keywords', payload: allKeywords });
  } catch (e) {
    console.warn(e);
  }
};

const detailsReducer = (state, action) => {
  switch (action.type) {
    case 'set-keywords':
      const keywords = action.payload;
      return { ...state, keywords };
    default:
      break;
  }
  return state;
};

const IndexProvider = props => {
  const [state, dispatch] = useReducer(detailsReducer, {
    isLoading: false,
    keywords: [...defaultKeywords],
  });
  useEffect(() => {
    downloadKeywords(dispatch);
  }, []);
  return <Provider value={{ ...state }}>{props.children}</Provider>;
};
export { IndexProvider, Context as IndexContext };

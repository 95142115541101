import React, { useReducer, useEffect, useMemo } from 'react';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import t from 'typy';
const Context = React.createContext();
const { Provider } = Context;

const downloadOverview = async dispatch => {
  const { accessToken } = await Auth.currentSession();
  const jwtToken = accessToken ? accessToken.jwtToken : '';
  const config = {
    headers: { Authorization: `Bearer ${jwtToken}`, responseType: 'json' },
  };

  const baseUrl = 'https://www.aashtowareproject.org';
  let url = `${baseUrl}/sf_api/organizations/overview`;

  try {
    const { data } = await axios.get(url, config);
    const payload = t(data).isEmptyArray ? [] : data;

    dispatch({ type: 'set-overview-data', payload });
  } catch (e) {
    console.warn(e);
  }
};

const hasStatusMatrix = agencies => agencies.filter(agency => 'status_matrix' in agency);

const filterPerComponent = (componentName, agencies) => {
  // For when ANY selected filters match
  const statuses = {
    licensed: [],
    implementing: [],
    production: [],
    'hosted production': [],
    'hosted non-production': [],
  };

  agencies.forEach(agency => {
    if (componentName === 'Hosted') {
      if (Object.values(agency.status_matrix).some(v => /\(H\)$/.test(v) && v !== 'I (H)')) {
        statuses['hosted production'].push(agency.abbr);
      } else if (Object.values(agency.status_matrix).some(v => v === 'I (H)')) {
        statuses['hosted non-production'].push(agency.abbr);
      }
    } else if (componentName in agency.status_matrix && agency.status_matrix[componentName]) {
      // console.log({ [agency.abbr]: componentName });
      switch (agency.status_matrix[componentName]) {
        case 'I':
        case 'I (H)':
          statuses.implementing.push(agency.abbr);
          break;
        case 'L':
        case 'L (H)':
          statuses.licensed.push(agency.abbr);
          break;
        default:
          statuses.production.push(agency.abbr);
          break;
      }
    }
  });

  return statuses;
};

const overviewReducer = (state, action) => {
  switch (action.type) {
    case 'set-overview-data':
      const agencies = hasStatusMatrix(action.payload);

      state = {
        ...state,
        // overview: agencies,
        components: {
          Estimation: filterPerComponent('Estimation', agencies),
          Preconstruction: filterPerComponent('Preconstruction', agencies),
          Bids: filterPerComponent('Bids', agencies),
          Construction: filterPerComponent('Construction', agencies),
          Materials: filterPerComponent('Materials', agencies),
          'Civil Rights & Labor': filterPerComponent('Civil Rights', agencies),
          'Data Analytics': filterPerComponent('Data Analytics', agencies),
          Hosted: filterPerComponent('Hosted', agencies),
          'Mobile Apps': filterPerComponent('Mobile Apps', agencies),
        },
      };
      break;
    case 'set-highlighted':
      // console.log('Highlight stuff', state.components, action.payload);

      const allActive = Array.from(
        new Set(action.payload.map(v => state.components[v.name][v.phase]))
      );

      // Determines all states that should be highlighted
      const highlighted = allActive.flat();

      // Determines states in which all active filters apply
      const vennCenter =
        allActive.length > 0
          ? Array.from(new Set(allActive.reduce((l, r) => l.filter(x => r.includes(x)))))
          : [];

      // console.log('All Active', allActive);
      // console.log('Highlighted', highlighted);
      // console.log('Intersection', vennCenter);

      state = { ...state, highlighted, vennCenter };
      break;
    case 'set-active-buttons':
      const { name, phase } = action.payload;
      const activeButtons = state.activeButtons;
      const existingIndex = state.activeButtons.findIndex(
        v => v.name === name && v.phase === phase
      );

      // If button isn't currently highlighted, add it
      if (existingIndex === -1) activeButtons.push(action.payload);
      // If button is already highlighted, remove it
      else activeButtons.splice(existingIndex, 1);

      state = { ...state, activeButtons };
      break;
    default:
      console.warn(`unknown action ${action.type}`);
  }
  return state;
};

const OverviewProvider = props => {
  const [state, dispatch] = useReducer(overviewReducer, {
    overview: [],
    highlighted: [],
    activeButtons: [],
    components: {
      Estimation: {
        licensed: [],
        implementing: [],
        production: [],
      },
      Preconstruction: {
        licensed: [],
        implementing: [],
        production: [],
      },
      Bids: {
        licensed: [],
        implementing: [],
        production: [],
      },
      Construction: {
        licensed: [],
        implementing: [],
        production: [],
      },
      Materials: {
        licensed: [],
        implementing: [],
        production: [],
      },
      'Civil Rights & Labor': {
        licensed: [],
        implementing: [],
        production: [],
      },
      'Data Analytics': {
        licensed: [],
        implementing: [],
        production: [],
      },
      Hosted: {
        'hosted production': [],
        'hosted non-production': [],
      },
      'Mobile Apps': {
        licensed: [],
        implementing: [],
        production: [],
      },
    },
  });

  useEffect(() => {
    downloadOverview(dispatch);
  }, [dispatch]);

  const setHighlighted = useMemo(() => {
    return payload => dispatch({ type: 'set-highlighted', payload });
  }, [dispatch]);

  const toggleActiveButtons = useMemo(() => {
    return payload => dispatch({ type: 'set-active-buttons', payload });
  }, [dispatch]);

  return (
    <Provider value={{ ...state, ...{ setHighlighted, toggleActiveButtons } }}>
      {props.children}
    </Provider>
  );
};

export { OverviewProvider, Context as OverviewContext };

import React, { useContext } from 'react';
import { DetailContext } from '../../store/detail-context';
const PhoneIcon = () => (
  <svg width="16" height="16" viewBox="0 0 24 24">
    <path fill="none" d="M0 0h24v24H0V0z" />
    <path d="M6.54 5c.06.89.21 1.76.45 2.59l-1.2 1.2c-.41-1.2-.67-2.47-.76-3.79h1.51m9.86 12.02c.85.24 1.72.39 2.6.45v1.49c-1.32-.09-2.59-.35-3.8-.75l1.2-1.19M7.5 3H4c-.55 0-1 .45-1 1 0 9.39 7.61 17 17 17 .55 0 1-.45 1-1v-3.49c0-.55-.45-1-1-1-1.24 0-2.45-.2-3.57-.57-.1-.04-.21-.05-.31-.05-.26 0-.51.1-.71.29l-2.2 2.2c-2.83-1.45-5.15-3.76-6.59-6.59l2.2-2.2c.28-.28.36-.67.25-1.02C8.7 6.45 8.5 5.25 8.5 4c0-.55-.45-1-1-1z" />
  </svg>
);

const EmailIcon = () => (
  <svg width="16" height="16" viewBox="0 0 24 24">
    <path fill="none" d="M0 0h24v24H0V0z" />
    <path d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6zm-2 0l-8 5-8-5h16zm0 12H4V8l8 5 8-5v10z" />
  </svg>
);

const PeopleMemo = React.memo(({ contacts }) => {
  return (
    <div className="am--sidebar-contact">
      {contacts.map((person, index) => {
        const { name, email, phone, roles } = person;
        return (
          <div key={index} className="contact">
            <div className="title">{name}</div>
            <div className="summary">
              {email && (
                <div>
                  <EmailIcon />
                  {email}
                </div>
              )}
              {phone && (
                <div>
                  <PhoneIcon /> {phone}
                </div>
              )}
              <div>
                Role{roles.length ? '' : 's'}: {roles.join(', ')}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
});

const People = () => {
  const { overview } = useContext(DetailContext);
  const { contacts } = overview;

  return contacts.length ? (
    <PeopleMemo contacts={contacts} />
  ) : (
    <div>No contact person found.</div>
  );
};
export default People;

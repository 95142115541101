import React, { useContext, useMemo } from 'react';
import { OverviewContext } from '../../store/overview-context';
import { TooltipContext } from '../../store/map-tooltip-context';
import MidPoints from '../../store/mid-points';

import {
  ComposableMap,
  ZoomableGroup,
  Geographies,
  Geography,
  Markers,
  Marker,
} from 'react-simple-maps';
import { geoAlbersUsa } from 'd3-geo';
import mapData from './data/gadm36_USA_1.json';
import { DetailContext } from '../../store/detail-context';
// import { IndexContext } from '../../store/index-context';

const getAbbr = varname => varname.split('|')[0].toLowerCase();

const setTooltipLocation = ({ setLocation, setVisible, setContent, setUsState, role }) => {
  return (geography, evt) => {
    const x = evt.clientX;
    const y = evt.clientY + window.pageYOffset;

    // console.log('Role: ', role);

    const content = (
      <div>
        <p>{geography.properties.NAME_1}</p>
        {role.name && (
          <p>
            {role.title}: {role.name}
          </p>
        )}
      </div>
    );
    setLocation(x, y);
    setVisible(true);
    setContent(content);
    setUsState(getAbbr(geography.properties.VARNAME_1));
  };
};

const onClick = selectDetailState => {
  return geography => {
    selectDetailState(geography.properties.NAME_1);
  };
};

const hideTooltip = ({ setVisible }) => {
  return () => {
    setVisible(false);
  };
};

const MapMemo = React.memo(
  ({
    setLocation,
    setVisible,
    setContent,
    setUsState,
    selectDetailState,
    selectedState,
    markers,
    highlighted,
    vennCenter,
    role,
  }) => {
    const getStyle = (currentState, varname) => {
      // Reset the map
      if (selectedState) {
        highlighted = [];
        vennCenter = [];
      }

      const isBlue = currentState === selectedState || highlighted.includes(getAbbr(varname));
      const isGreen = vennCenter?.includes(getAbbr(varname));

      let stateFill = '#ECEFF1';
      if (isBlue) stateFill = '#3d70b2';
      if (isGreen) stateFill = '#7ad153';

      return {
        default: {
          fill: stateFill,
          stroke: '#607D8B',
          strokeWidth: 0.75,
          outline: 'none',
        },
        hover: {
          fill: '#607D8B',
          stroke: '#607D8B',
          strokeWidth: 0.75,
          outline: 'none',
          cursor: 'pointer',
        },
        pressed: {
          fill: '#41d6c3',
          stroke: '#607D8B',
          strokeWidth: 0.75,
          outline: 'none',
        },
      };
    };

    return (
      <ComposableMap
        projection={geoAlbersUsa}
        projectionConfig={{
          scale: 1,
          rotation: [0, 0, 0],
        }}
        width={980}
        height={551}
        style={{
          width: '100%',
          height: 'auto',
        }}
      >
        <ZoomableGroup center={[0, 20]}>
          <Geographies geography={mapData} disableOptimization>
            {(geographies, projection) =>
              geographies.map((geography, i) => {
                return (
                  <Geography
                    key={i}
                    cacheId={`geography-${i}-${selectedState === geography.properties.NAME_1}`}
                    geography={geography}
                    projection={projection}
                    onMouseMove={setTooltipLocation({
                      setLocation,
                      setVisible,
                      setContent,
                      setUsState,
                      role,
                    })}
                    onMouseLeave={hideTooltip({ setVisible })}
                    onClick={onClick(selectDetailState)}
                    style={getStyle(geography.properties.NAME_1, geography.properties.VARNAME_1)}
                  />
                );
              })
            }
          </Geographies>
          <Markers>
            {markers.map((stateName, i) => {
              return (
                <Marker
                  key={i}
                  marker={{ coordinates: MidPoints[stateName] }}
                  style={{
                    default: { stroke: '#455A64' },
                    hover: { stroke: '#FF5722' },
                    pressed: { stroke: '#FF5722' },
                  }}
                >
                  <g transform="translate(-12, -24)" style={{ pointerEvents: 'none' }}>
                    <path
                      fill="none"
                      strokeWidth="2"
                      strokeLinecap="square"
                      strokeMiterlimit="10"
                      strokeLinejoin="miter"
                      d="M20,9c0,4.9-8,13-8,13S4,13.9,4,9c0-5.1,4.1-8,8-8S20,3.9,20,9z"
                    />
                    <circle
                      fill="none"
                      strokeWidth="2"
                      strokeLinecap="square"
                      strokeMiterlimit="10"
                      strokeLinejoin="miter"
                      cx="12"
                      cy="9"
                      r="3"
                    />
                  </g>
                </Marker>
              );
            })}
          </Markers>
        </ZoomableGroup>
      </ComposableMap>
    );
  }
);

const MapComponent = () => {
  const { setLocation, setVisible, setContent, setUsState, usState } = useContext(TooltipContext);
  const {
    selectDetailState,
    stateName: selectedState,
    markers,
    selected,
  } = useContext(DetailContext);
  const { highlighted, vennCenter, overview } = useContext(OverviewContext);

  const props = useMemo(() => {
    function getUserWithRole(usState) {
      const currentState = overview.find(v => v.abbr === usState);
      // console.log(overview, currentState);

      if (currentState && 'contacts' in currentState && currentState.contacts.length) {
        const users = currentState.contacts.filter(contact => {
          return contact.roles.some(role => role === selected);
        });

        if (!!users.length) console.log({ users, contacts: currentState.contacts });
        return users.length ? users.pop().name : '';
      }

      return '';
    }

    const role = {
      title: selected,
      name: getUserWithRole(usState),
    };
    return {
      setLocation,
      setVisible,
      setContent,
      setUsState,
      selectDetailState,
      selectedState,
      markers,
      highlighted,
      vennCenter,
      role,
    };
  }, [
    selected,
    usState,
    setLocation,
    setVisible,
    setContent,
    setUsState,
    selectDetailState,
    selectedState,
    markers,
    highlighted,
    vennCenter,
    overview,
  ]);
  return <MapMemo {...props} />;
};

export default MapComponent;

import React, { useContext } from 'react';
import { UserContext } from '../../store/user-context';
import { Button } from 'carbon-components-react';
import { Auth } from 'aws-amplify';
import { header } from './header.module.scss';

const HeaderMemo = React.memo(({ userName }) => {
  return (
    <header className={`${header} am--header-wrapper`}>
      <div className="bx--type-mega"> Agency Map </div>
      <div>
        {userName && userName !== 'UNKNOWN' && `Hi ${userName} `}
        {userName && <Button onClick={() => Auth.signOut()}>Logout</Button>}
      </div>
    </header>
  );
});

const Header = () => {
  const { userName } = useContext(UserContext);
  return <HeaderMemo {...{ userName }} />;
};

export default Header;

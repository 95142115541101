import t from 'typy';
import StateNames from './state-names';

const objectFlip = obj => {
  const ret = {};
  Object.keys(obj).forEach(key => {
    ret[obj[key]] = key;
  });
  return ret;
};
const abbrToStateName = objectFlip(StateNames);

const safeArray = (obj, path) => {
  const safeObj = t(obj, path).safeObject;
  if (t(safeObj).isArray) {
    return safeObj;
  }
  console.warn(`${path} is not an array in:`);
  return [];
};

const getKeyword = ({ obj, key, abbr, prefix, customKey }) => {
  if (!key) {
    return {};
  }

  if (prefix) {
    key = `${prefix}:${key}`;
  }

  if (customKey) {
    key = customKey;
  }

  return obj[key]
    ? { [key]: [...obj[key], abbrToStateName[abbr]] }
    : { [key]: [abbrToStateName[abbr]] };
};

const stateReducer = (keywords, state) => {
  const { accountManager, abbr: abbriv, isHosted, contacts } = state;
  const abbr = abbriv ? abbriv.toUpperCase() : '';

  if (!abbrToStateName[abbr]) {
    return keywords;
  }

  function collectRoles(allRoles, contact) {
    return [...allRoles, contact.roles].flat();
  }

  function aggregateStatesWithRole(keywords, roles) {
    for (const role in roles) {
      if (keywords[role] && !keywords[role].includes(roles[role])) {
        keywords[role].push(roles[role]);
      } else {
        keywords[role] = [roles[role]];
      }
    }
  }

  const roles = Object.fromEntries(
    contacts.reduce(collectRoles, []).map(role => {
      return [role, abbrToStateName[abbr]];
    })
  );

  aggregateStatesWithRole(keywords, roles);

  return {
    ...keywords,
    ...getKeyword({
      obj: keywords,
      key: accountManager,
      abbr
    }),
    ...getKeyword({
      obj: keywords,
      key: isHosted,
      abbr,
      customKey: 'Hosted'
    })
  };
};

const getAllKeywords = payload => {
  return safeArray(payload)
    .map(x => (x ? x : {}))
    .reduce(stateReducer, {});
};

export const translate = payload => {
  const allKeywords = getAllKeywords(payload);

  return Object.keys(allKeywords).map(keyword => ({
    id: keyword,
    text: keyword,
    matches: allKeywords[keyword].join(',')
  }));
};

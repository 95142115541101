import React, { useReducer, useEffect, useMemo, createContext } from 'react';
import StateNames from './state-names';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { translate } from './overview-translator';
import t from 'typy';
const Context = createContext();
const { Provider } = Context;

const downloadStateOverview = async (stateName, dispatch) => {
  const { accessToken } = await Auth.currentSession();
  const jwtToken = accessToken ? accessToken.jwtToken : '';
  const config = {
    headers: { Authorization: `Bearer ${jwtToken}`, responseType: 'json' },
  };
  const abbr = StateNames[stateName];
  const baseUrl = 'https://www.aashtowareproject.org';
  const url = `${baseUrl}/sf_api/organizations/overview/${abbr}`;

  try {
    const { data } = await axios.get(url, config);
    const payload = t(data).isEmptyObject
      ? { abbr, contacts: [], productGroups: [], emptyResult: true }
      : data;

    dispatch({ type: 'set-detail-data', payload });
  } catch (e) {
    console.warn(e);
  }
};

const detailsReducer = (state, action) => {
  switch (action.type) {
    case 'select-detail-state':
      const stateName = action.payload;
      if (stateName !== state.stateName) {
        state = { ...state, stateName, isLoading: true };
      }
      if (stateName === null && state.isLoading) {
        const overview = {
          contacts: [],
          productGroups: [],
          accountManager: '',
          notes: '',
        };
        state = { ...state, overview, isLoading: false };
      }
      break;
    case 'set-markers':
      const { payload: markers } = action;
      if (
        markers.length !== state.markers.length ||
        markers.some(x => !state.markers.includes(x))
      ) {
        state = { ...state, markers };
      }
      break;
    case 'set-selected':
      state = { ...state, selected: action.payload };
      break;
    case 'set-detail-data':
      const { stateName: name } = state;
      const abbr = StateNames[name];
      if (
        abbr &&
        action.payload &&
        action.payload.abbr &&
        abbr.toUpperCase() === action.payload.abbr.toUpperCase()
      ) {
        const { emptyResult, ...rest } = action.payload;
        const overview = emptyResult ? rest : translate(rest);
        overview.contacts = sortRoles(overview.contacts);
        state = { ...state, overview, isLoading: false };
      } else {
        console.warn(`${name} didnt match payload. Skipping..`);
      }
      break;
    default:
      console.warn(`unknown action ${action.type}`);
  }
  return state;
};

const DetailProvider = props => {
  const [state, dispatch] = useReducer(detailsReducer, {
    isLoading: false,
    stateName: null,
    overview: {
      contacts: [],
      productGroups: [],
      accountManager: '',
      notes: '',
    },
    markers: [],
  });
  const { stateName } = state;
  useEffect(() => {
    if (stateName) {
      downloadStateOverview(stateName, dispatch);
    }
  }, [stateName]);

  const selectDetailState = useMemo(() => {
    return payload => dispatch({ type: 'select-detail-state', payload });
  }, [dispatch]);

  const setMarkers = useMemo(() => {
    return states => {
      const payload = states ? states.split(',') : [];
      dispatch({ type: 'set-markers', payload });
      if (payload.length === 1) {
        dispatch({ type: 'select-detail-state', payload: payload[0] });
      } else {
        dispatch({ type: 'select-detail-state', payload: null });
        // Set the tooltip-text to contain the roles matching a role searched for if
        // what is selected is, in fact, a
      }
    };
  }, [dispatch]);

  const setSelected = useMemo(() => {
    return text => dispatch({ type: 'set-selected', payload: text });
  }, [dispatch]);

  return (
    <Provider value={{ ...state, ...{ selectDetailState }, ...{ setMarkers }, ...{ setSelected } }}>
      {props.children}
    </Provider>
  );
};

function prioritizeEUD(contacts) {
  contacts.some((contact, index) => {
    if (contact.roles.includes('Agency EUD'))
      return contacts.unshift(contacts.splice(index, 1).shift());
    return false;
  });
  return contacts;
}

function prioritizePTF(contacts) {
  contacts.some((contact, index) => {
    if (contact.roles.includes('PTF Member'))
      return contacts.unshift(contacts.splice(index, 1).shift());
    return false;
  });
  return contacts;
}

function prioritizeMostRoles(contacts) {
  return contacts.sort((prev, next) => next.roles.length - prev.roles.length);
}

/**
 * @param {array} contacts
 * @returns {array} returns the sorted contacts.
 */
function sortRoles(contacts) {
  return prioritizeEUD(prioritizePTF(prioritizeMostRoles(contacts)));
}

export { DetailProvider, Context as DetailContext };

import React, { useReducer, useMemo } from 'react';

const Context = React.createContext();
const { Provider } = Context;

const reducer = (state, action) => {
  switch (action.type) {
    case 'set_tooltip_text':
      if (state.text !== action.payload) {
        return { ...state, text: action.payload };
      }
      break;
    case 'set_tooltip_visible':
      if (state.visible !== action.payload) {
        return { ...state, visible: action.payload };
      }
      break;
    case 'set_tooltip_location':
      if (
        state.location.x !== action.payload.x ||
        state.location.y !== action.payload.y
      ) {
        return { ...state, location: action.payload };
      }
      break;
    case 'set_tooltip_state':
      if (state.usState !== action.payload) {
        return { ...state, usState: action.payload };
      }
      break;
    default:
      console.warn(`unknown action $(action.type) `);
      break;
  }
  return state;
};

const TooltipProvider = props => {
  const [state, dispatch] = useReducer(reducer, {
    visible: false,
    location: { x: 0, y: 0 },
    text: ''
  });
  const setLocation = useMemo(() => {
    return (x, y) =>
      dispatch({ type: 'set_tooltip_location', payload: { x, y } });
  }, [dispatch]);

  const setVisible = useMemo(() => {
    return visible =>
      dispatch({ type: 'set_tooltip_visible', payload: visible });
  }, [dispatch]);

  const setContent = useMemo(() => {
    return content => dispatch({ type: 'set_tooltip_text', payload: content });
  }, [dispatch]);

  const setUsState = useMemo(() => {
    return state => dispatch({ type: 'set_tooltip_state', payload: state });
  }, [dispatch]);

  return (
    <Provider value={{ ...state, ...{ setLocation, setVisible, setContent, setUsState } }}>
      {props.children}
    </Provider>
  );
};

export { TooltipProvider, Context as TooltipContext };

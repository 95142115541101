import React from 'react';
import { DataTable } from 'carbon-components-react';

const {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader
} = DataTable;

const headers = [
  {
    key: 'name',
    header: 'Product'
  },
  {
    key: 'installed',
    header: 'Impl. Status'
  }
];

const renderProp = title => ({ rows, headers, getHeaderProps }) => (
  <TableContainer title={title}>
    <Table>
      <TableHead>
        <TableRow>
          {headers.map(header => (
            <TableHeader {...getHeaderProps({ header })}>
              {header.header}
            </TableHeader>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map(row => (
          <TableRow key={row.id}>
            {row.cells.map(cell => (
              <TableCell key={cell.id}>{cell.value}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

const ProductTable = React.memo(({ group, ismLegend }) => {
  const rows = group.map((product, index) => {
    var { installed } = product;
    installed = ismLegend[installed] ? ismLegend[installed] : installed;
    return { id: `${index}`, ...product, ...{ installed } };
  });
  return (
    <DataTable
      rows={rows}
      headers={headers}
      render={renderProp(rows[0].group)}
    />
  );
});

export default ProductTable;

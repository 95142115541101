import React from 'react';
import Header from '../header/header';
import Map from '../map/map';
import Key from '../key/key';
import Sidebar from '../sidebar/sidebar';
import { OverviewProvider } from '../../store/overview-context';
import { DetailProvider } from '../../store/detail-context';

import { wrapper, main, map, detail } from './home-page.module.scss';
import SearchBar from '../search-bar/search-bar';

const HomePage = props => (
  <DetailProvider>
    <div className={wrapper}>
      <Header />
      <div className={main}>
        <OverviewProvider>
          <aside className="am--raised">
            <Key />
          </aside>
          <div className={map}>
            <SearchBar />
            <Map />
          </div>
        </OverviewProvider>
        <aside className={`${detail} am--raised`}>
          <Sidebar />
        </aside>
      </div>
    </div>
  </DetailProvider>
);

export default HomePage;

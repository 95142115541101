import React, { useContext } from 'react';
import { DetailContext } from '../../store/detail-context';
import SidebarNav from '../sidebar-nav/sidebar-nav';
import { wrapper, body, header, footer } from './sidebar.module.scss';

const Sidebar = () => {
  const { stateName, isLoading, overview } = useContext(DetailContext);
  const { isHosted, accountManager } = overview;
  if (!stateName) {
    return <div>Select a state from the map to view details.</div>;
  }
  return (
    <div className={`${wrapper}`}>
      <div className={`${header} am--sidebar-padding bx--type-alpha `}>
        {`${stateName}${isHosted ? ' (hosted)' : ''}`}
      </div>
      <div className={`${body} am--sidebar-padding`}>
        <SidebarNav />
      </div>
      <div className={`${footer} am--sidebar-footer`}>
        <div className="am--sidebar-footer__item">
          <div className="label">Account Manager</div>
          <div className="content">
            {isLoading ? 'Loading..' : accountManager}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

import React, { useContext, useEffect } from 'react';
import Amplify from 'aws-amplify';
import { withAuthenticator } from 'aws-amplify-react';
import { UserContext } from './store/user-context';
import HomePage from './components/home-page/home-page';

const aws_exports = {
  Auth: {
    userPoolId: 'us-west-2_LFsuL3S98',
    userPoolWebClientId: '7k81n9o7368e31bfk8jf6guub5',
  },
};
Amplify.configure(aws_exports);

const App = ({ authData }) => {
  const { setAuthData } = useContext(UserContext);

  useEffect(() => {
    setAuthData(authData);
  }, [authData, setAuthData]);

  return <HomePage />;
};

export default withAuthenticator(App);

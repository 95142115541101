import React, { useContext } from 'react';
import { OverviewContext } from '../../store/overview-context';
import { DetailContext } from '../../store/detail-context';
import { column, button, item } from './key.module.scss';
import { Button, Accordion, AccordionItem } from 'carbon-components-react';

const Key = () => {
  const { components, setHighlighted, toggleActiveButtons, activeButtons } =
    useContext(OverviewContext);
  const { selectDetailState } = useContext(DetailContext);

  const onClick = (name, phase) => {
    toggleActiveButtons({ name, phase });
    setHighlighted(activeButtons);
    // Reset the map
    selectDetailState(null);
    return;
  };

  const filterPhase = (phase, component) => {
    if (component === 'Hosted') {
      if (/^Hosted/i.test(phase)) return true;
      return false;
    }
    if (component !== 'Hosted' && /^Hosted/i.test(phase)) return false;
    return true;
  };

  return (
    <Accordion>
      {Object.keys(components).map(name => (
        <AccordionItem title={name} className={item} key={name}>
          <div className={column}>
            {Object.keys(components[name])
              .filter(phase => filterPhase(phase, name))
              .map(phase => (
                <Button
                  className={button}
                  onClick={() => onClick(name, phase)}
                  disabled={!components[name][phase].length}
                  key={`${components[name]}-${phase}`}
                  kind={
                    activeButtons.some(v => v.name === name && v.phase === phase)
                      ? 'primary'
                      : 'ghost'
                  }
                >
                  {phase}
                </Button>
              ))}
          </div>
        </AccordionItem>
      ))}
    </Accordion>
  );
};

export default Key;

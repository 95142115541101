import React from 'react';
import { DataTable } from 'carbon-components-react';

const { TableContainer, Table, TableRow, TableBody, TableCell } = DataTable;

const renderProp = title => ({ rows }) => (
  <TableContainer title={title}>
    <Table>
      <TableBody>
        {rows.map(row => (
          <TableRow key={row.id}>
            {row.cells.map(cell => (
              <TableCell key={cell.id}>{cell.value}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

const EnvironmentTable = React.memo(({ env }) => {
  env = env ? env : {};
  const { name, isActive, ...rest } = env;
  rest['Active'] = isActive ? 'Yes' : 'No';
  const rows = Object.keys(rest)
    .map((key, index) => ({ id: `${index}`, key, value: rest[key] }))
    .filter(x => x.value);
  return (
    <DataTable
      rows={rows}
      headers={[
        {
          key: 'key',
          header: 'key'
        },
        {
          key: 'value',
          header: 'value'
        }
      ]}
      render={renderProp(name)}
    />
  );
});

export default EnvironmentTable;

import React, { useState, useRef, useEffect, useContext } from 'react';
import { TooltipContext } from '../../store/map-tooltip-context';

const TooltipMemo = React.memo(({ location, visible, text }) => {
  const [state, setState] = useState({ width: 0, height: 0 });
  const toolTipRef = useRef();
  useEffect(() => {
    const { width, height } = toolTipRef.current.getBoundingClientRect();
    if (state.width !== width || state.height !== height) setState({ ...state, width, height });
  }, [state]);

  return (
    <div
      ref={toolTipRef}
      className={`bx--tooltip ${visible ? 'bx--tooltip--shown' : ''}`}
      role="tooltip"
      style={{
        top: location.y + 30,
        left: location.x - state.width / 2,
        position: 'absolute',
        margin: 0,
        opacity: 1,
        right: 'auto',
      }}
    >
      <span className={'bx--tooltip__caret'} />
      <section>{text}</section>
    </div>
  );
});

const ToolTip = () => {
  const context = useContext(TooltipContext);
  return <TooltipMemo {...context} />;
};

export default ToolTip;
